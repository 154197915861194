import React from 'react';
import PropTypes from 'prop-types';
import * as componentStyles from './NotFoundPageContent.style.js';
import { connect as connectFela } from 'react-fela';
import Heading1 from '../Heading1';
import Link from '../Link';
import { LinkStyle, LinkType } from '../Link/Link.style';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

const NotFoundPageContent = ({ styles }) => (
    <main className={styles.wrapper}>
        <div className={styles.inner}>
            <div className={styles.titleWrapper}>
                <Heading1 color="white" className={styles.title}>
                    <FormattedHTMLMessage tagName="span" id="notfound.title" />
                </Heading1>
            </div>
            <Link type={LinkType.GATSBY} styleType={LinkStyle.BUTTON} to="/">
                <FormattedMessage id="notfound.button" />
            </Link>
        </div>
    </main>
);

NotFoundPageContent.propTypes = {
    styles: PropTypes.objectOf(PropTypes.string),
};

export default connectFela(componentStyles)(NotFoundPageContent);
