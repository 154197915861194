import React, { useLayoutEffect } from 'react';
import withPageContext from '../pageContext';
import SEO from '../components/SEO';
import HeaderScroller from '../components/HeaderScroller';
import { LinkType } from '../components/Link/Link.style';
import NotFoundPageContent from '../components/404/NotFoundPageContent';

const getDefaultLanguage = require('../../src/i18n/getDefaultLanguage');
const getLocalizedPath = require('../../src/i18n/getLocalizedPath');

const links = [
    { to: '/', labelId: 'home.menu.top', type: LinkType.GATSBY },
    { to: '/features', labelId: 'home.menu.features', type: LinkType.GATSBY },
    { to: '/festival', labelId: 'home.menu.festival', type: LinkType.GATSBY },
    { to: '/conference', labelId: 'home.menu.conference', type: LinkType.GATSBY },
    { to: '/#contacts', labelId: 'home.menu.contacts', asButton: true, type: LinkType.GATSBY },
];

const NotFoundPage = ({ pageContext, location, setLocale }) => {
    // Change language of 404 based on user url address
    useLayoutEffect(() => {
        const languageInUrl = location.pathname.split('/')[1] || getDefaultLanguage();
        if (languageInUrl === pageContext.locale) return;

        if (getLocalizedPath('notFound', languageInUrl)) {
            setLocale(languageInUrl);
        }
    }, [location, pageContext, setLocale]);
    return (
        <>
            <SEO title="title.notFound" desc="description.notFound">
                <meta name="robots" content="noindex" />
            </SEO>
            <HeaderScroller links={links} withLanguageSwitcher={false} />
            <NotFoundPageContent />
        </>
    );
};

export default withPageContext(NotFoundPage);
