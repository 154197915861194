import bg from '../../images/404bg.svg';

export const wrapper = ({ theme }) => ({
    backgroundColor: '#1F252C',
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
    height: '100%',
    width: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 3,
});

export const inner = ({ theme }) => ({
    height: '100%',
    width: '75%',
    marginTop: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    alignContent: 'center',
    flexWrap: 'wrap',
});

export const titleWrapper = ({ theme }) => ({
    flex: '1 0 100%', // full width
});

export const title = ({ theme }) => ({
    color: theme.colors.white,
    fontWeight: '800 !important',
    maxWidth: '22rem',
    lineHeight: '4.5',
    marginBottom: '2.5rem',
    padding: '0 !important',
});
